<template>
  <div
    style="width:100%; height: 100%; "
    class="d-flex align-center justify-center "
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      :width="7"
    />
  </div>

</template>

<script>
export default {

}
</script>
